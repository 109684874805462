import React from 'react';
import Link from 'next/link';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import StarIcon from 'assets/star.svg';

import styles from './styles';

const Modes = {
  SELECT: 'select',
  VIEW: 'view',
};

const TutorCard = React.memo(props => {
  const {
    id,
    firstName,
    lastName,
    level,
    rating,
    shortBio,
    maxPrice,
    minPrice,
    image,
    classes,
    mode = Modes.VIEW,
    isSelected = false,
    onClick = () => {},
  } = props;
  const WrapComponent = mode === Modes.SELECT ? React.Fragment : Link;
  const lname = lastName?.charAt(0) || '';
  const name = lname ? `${firstName} ${lname}.` : `${firstName}`;
  const rootStyle = clsx({
    [classes.root]: true,
    [classes.selected]: isSelected,
  });
  const selectButtonStyle = clsx({
    [classes.button]: true,
    [classes.buttonSelect]: true,
    [classes.buttonSelectAccent]: isSelected,
  });
  const linkProps = mode === Modes.SELECT ? {} : {
    href: '/tutor/[id]',
    as: `/tutor/${id}`,
  };

  const mouseDown = e => {
    e.stopPropagation();
  };

  const price = maxPrice === minPrice ? `$${maxPrice}` : `$${minPrice.toFixed(2)} - $${maxPrice.toFixed(2)}`;

  return (
    <WrapComponent {...linkProps}>
      <div className={classes.outer}>
        <Card className={rootStyle} variant="outlined" component="div" onClick={onClick}>
          <CardActionArea component="div">
            <CardContent className={classes.inner}>
              <div className={classes.flexArea}>
                <Box className={classes.imageWrap}>
                  <div className={classes.imageInner}>
                    <CardMedia className={classes.image} image={image} />
                    <div className={classes.rating}>
                      <Typography className={classes.ratingText}>
                        {Number(rating).toFixed(0)}
                      </Typography>
                      <img
                        className={classes.ratingImage}
                        src={StarIcon}
                        alt=""
                      />
                    </div>
                  </div>
                </Box>
                <Box className={classes.textBox}>
                  <Typography variant="h5" component="h2" className={classes.title}>
                    {name}
                    {level && (
                    <Typography component="span" className={classes.level}>
                      {`| ${level}`}
                    </Typography>
                    )}
                  </Typography>

                  <Typography className={classes.shortBio} color="textSecondary">
                    {shortBio}
                  </Typography>

                  <div className={classes.footer}>
                    <Typography color="textSecondary" className={classes.price}>
                      {price && (
                      <>
                        <Typography component="span" className={classes.priceBold}>
                          {`${price}/`}
                        </Typography>
                        <Typography component="span" className={classes.priceText}>
                          per hour
                        </Typography>
                      </>
                      )}
                    </Typography>
                  </div>
                </Box>
              </div>

              <div className={classes.buttonRow}>
                {mode === Modes.VIEW && (
                  <>
                    <Button
                      color="primary"
                      className={`${classes.button} ${classes.buttonOutline}`}
                      variant="contained"
                      onMouseDown={mouseDown}
                    >
                      Profile
                    </Button>

                    <Button
                      color="primary"
                      className={classes.button}
                      variant="contained"
                      onMouseDown={mouseDown}
                    >
                      Book
                    </Button>
                  </>
                )}

                {mode === Modes.SELECT && (
                  <Button
                    color="primary"
                    className={selectButtonStyle}
                    variant="contained"
                    onMouseDown={mouseDown}
                  >
                    {isSelected ? 'Selected' : 'Select'}
                  </Button>
                )}
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </WrapComponent>
  );
});

export default withStyles(styles)(TutorCard);

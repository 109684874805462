const styles = ({ palette, breakpoints }) => ({
  outer: {
    minWidth: 275,
    maxWidth: 800,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 15,
    position: 'relative',
    zIndex: 1,

    [breakpoints.up('md')]: {
      paddingTop: 25,

      '&::after': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: -30,
        width: 140,
        height: 137,
        zIndex: -1,
        borderRadius: '100%',
      },

      '&:nth-child(3n+1)::after': {
        backgroundColor: '#FEFBF2',
      },
      '&:nth-child(3n+2)::after': {
        backgroundColor: '#EEFBF7',
      },
      '&:nth-child(3n+3)::after': {
        backgroundColor: '#F4F6F9',
      },
    },
  },
  root: {
    position: 'relative',
    border: 0,
    backgroundColor: '#ffffff',
    boxShadow: '0 0 8px 0 rgba(0,0,0,0.14)',
    borderRadius: 10,
  },
  selected: {
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: palette.primary.main,
      borderRadius: 10,
      pointerEvents: 'none',
    },
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  inner: {
    padding: 0,
  },
  flexArea: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    fontSize: 16,
    lineHeight: 1.2222222,
    fontWeight: 900,
    marginTop: 0,
    marginBottom: 10,
    color: palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    [breakpoints.up('md')]: {
      fontSize: 18,
      marginBottom: 18,
    },
  },
  level: {
    color: palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.375,
    display: 'block',
    marginLeft: 6,
    [breakpoints.up('md')]: {
      fontSize: 16,

    },
  },
  pos: {
    marginBottom: 12,
  },
  imageWrap: {
    paddingLeft: 14,
    paddingRight: 14,
    paddingTop: 20,
    [breakpoints.up('md')]: {
      paddingLeft: 30,
      paddingRight: 20,
      paddingTop: 29,
      paddingBottom: 36,
    },
  },
  imageInner: {
    position: 'relative',
  },
  image: {
    borderRadius: '50%',
    width: 69,
    height: 69,
    [breakpoints.up('md')]: {
      width: 110,
      height: 110,
    },
  },
  rating: {
    position: 'absolute',
    bottom: -10,
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: 12.5,
    width: 31,
    height: 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: palette.orange,
    color: '#ffffff',
    [breakpoints.up('md')]: {
      width: 50,
      height: 25,
    },
  },
  ratingText: {
    fontSize: 12,
    marginRight: 2,
    marginLeft: 3,
    fontWeight: 900,
    [breakpoints.up('md')]: {
      fontSize: 16,
      marginLeft: 0,
      marginRight: 4,
    },
  },
  ratingImage: {
    width: 10,
    height: 10,
    [breakpoints.up('md')]: {
      width: 14,
      height: 14,
    },
  },
  textBox: {
    paddingTop: 20,
    paddingLeft: 3,
    paddingRight: 14,
    width: '100%',
    [breakpoints.up('md')]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 21,
      paddingBottom: 14,
    },
  },
  bookButton: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: '8px 0',
  },
  shortBio: {
    overflow: 'hidden',
    '-webkit-line-clamp': 3,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    color: palette.text.secondary,
    fontSize: 14,
    lineHeight: 1.25,
    marginBottom: 15,
    [breakpoints.up('md')]: {
      '-webkit-line-clamp': 2,
      height: 40,
      marginBottom: 20,
      paddingRight: 10,
      fontSize: 16,
    },
  },
  reviews: {
    fontSize: 14,
    marginLeft: 4,
    opacity: 0.76,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  price: {
    display: 'flex',
    color: palette.text.primary,
    fontSize: 14,
    lineHeight: 1.2222222,
    [breakpoints.up('md')]: {
      fontSize: 16,
    },
  },
  priceBold: {
    fontWeight: '900',
    fontSize: 14,
    lineHeight: 1.2222222,
    [breakpoints.up('md')]: {
      fontSize: 16,
    },
  },
  priceText: {
    fontSize: 14,
    lineHeight: 1.2222222,
    [breakpoints.up('md')]: {
      fontSize: 16,
    },
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 24,
    [breakpoints.up('md')]: {
      position: 'absolute',
      bottom: 0,
      right: 22,
    },
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 5,
    marginRight: 5,
    height: 30,
    width: 107,
    textTransform: 'none',
    fontSize: 16,
    fontWeight: '900',
    boxShadow: 'none',
    [breakpoints.up('md')]: {
      height: 40,
    },
  },
  buttonOutline: {
    backgroundColor: '#ffffff',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.primary.main,
    color: palette.primary.main,

    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  buttonSelect: {
    backgroundColor: '#ffffff',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.primary.main,
    color: palette.text.primary,
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  buttonSelectAccent: {
    backgroundColor: palette.primary.main,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: palette.primary.main,
    },
  },
});

export default styles;
